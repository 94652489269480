import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Tabs, Modal } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateOpenModalControlPanelBank } from "../../../redux/monitoring/monitoringSlice";
// COMPONENTS
import ButtonClose from "../../components/buttons/ButtonClose";
import ControlPanelBankHeader from "./ControlPanelBankHeader";
import ControlPanelBankVIP from "./ControlPanelBankVIP";
import ControlPanelBankHallCalls from "./ControlPanelBankHallCalls";
import ControlPanelBankFaults from "./ControlPanelBankFaults";
// INTERFACES
import {
	SocketMessageInterface,
	MonitoringModalSettingsInterface,
	ControlPanelBankFloors,
} from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";

function ControlPanelBank({ socketMessage }: MonitoringModalSettingsInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const dispatch = useDispatch();
	const BLANK_SOCKET_MESSAGE = {
		bank_id: "",
		lift_id: "",
		lift_type: "",
		bank_name: "",
		lift_name: "",
		car_calls: { front: [], rear: [], front_lock: [], rear_lock: [] },
		hall_calls: {
			up: {
				front: [],
				rear: [],
				front_lock: [],
				rear_lock: [],
			},
			down: {
				front: [],
				rear: [],
				front_lock: [],
				rear_lock: [],
			},
			up_assigned: { front: [], rear: [], front_lock: [], rear_lock: [] },
			down_assigned: { front: [], rear: [], front_lock: [], rear_lock: [] },
		},
		faults: false,
		position: 1,
		icon: {
			type: "",
			name: "",
			description: "",
		},
		service_mode: {
			name: "",
			description: "",
		},
		heartbeat: 0,
		direction: "",
	};

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openModalControlPanelBank, controlPanelDrawerPlacement, layoutSelected } = useSelector(
		(state: RootState) => state.monitoring
	);
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [tabActiveKey, setTabActiveKey] = useState<string>("HALL_CALLS");
	const [displayFrontDoors, setDisplayFrontDoors] = useState<boolean>(true);
	const [displayRearDoors, setDisplayRearDoors] = useState<boolean>(true);
	const [bankFloors, setBankFloors] = useState<ControlPanelBankFloors[]>([]);
	const [localSocketMessage, setLocalSocketMessage] = useState<SocketMessageInterface>(BLANK_SOCKET_MESSAGE);
	const [liftVip, setLiftVip] = useState<any>({});
	const [socketMessageVip, setSocketMessageVip] = useState<SocketMessageInterface>(BLANK_SOCKET_MESSAGE);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [vipServiceModeStopped, setVipServiceModeStopped] = useState<boolean>(false);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const clearVipMode = () => {
		setLiftVip({});
		setSocketMessageVip(BLANK_SOCKET_MESSAGE);
	};

	const onClickClose = () => {
		if (liftVip.id && socketMessageVip.bank_id) {
			setOpenModal(true);
		} else {
			dispatch(updateOpenModalControlPanelBank(false));
		}
	};

	const onClickOk = () => {
		setOpenModal(false);
		dispatch(updateOpenModalControlPanelBank(false));
	};

	const onClickCancel = () => {
		setOpenModal(false);
	};

	const generateFrontRearConfiguration = () => {
		let bankHasFrontDoors = false;
		let bankHasRearDoors = false;
		const newBankFloors: ControlPanelBankFloors[] = [];

		layoutSelected.bank_floors.map((floor: any) => {
			// Check if has front or rear doors
			if (floor.front_door) {
				bankHasFrontDoors = true;
			}
			if (floor.rear_door) {
				bankHasRearDoors = true;
			}
			newBankFloors.push({
				id: floor.id,
				name: floor.name,
				floor_no: floor.floor_no,
				front_door: floor.front_door,
				rear_door: floor.rear_door,
			});
			return true;
		});
		setBankFloors(newBankFloors);
		setDisplayFrontDoors(bankHasFrontDoors);
		setDisplayRearDoors(bankHasRearDoors);
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (
			layoutSelected &&
			layoutSelected.id &&
			socketMessage &&
			socketMessage.bank_id &&
			layoutSelected.id === socketMessage.bank_id &&
			openModalControlPanelBank
		) {
			if (socketMessage.lift_type === "ELEVATOR") {
				setLocalSocketMessage(socketMessage);
				if (socketMessage.service_mode && socketMessage.service_mode.name === "VIP") {
					setSocketMessageVip(socketMessage);
					const index = layoutSelected.lifts.findIndex((object: any) => object.id === socketMessage.lift_id);
					if (index >= 0) {
						setLiftVip(layoutSelected.lifts[index]);
					}
				}
				if (
					liftVip &&
					liftVip.id &&
					socketMessage.lift_id &&
					socketMessage.lift_id === liftVip.id &&
					socketMessage.service_mode &&
					socketMessage.service_mode.name !== "VIP"
				) {
					setVipServiceModeStopped(true);
					setTimeout(() => {
						setVipServiceModeStopped(false);
					}, 100);
				}
			}
		}
	}, [socketMessage]);

	useEffect(() => {
		if (layoutSelected && layoutSelected.bank_floors && layoutSelected.lifts) {
			generateFrontRearConfiguration();
		}
	}, [layoutSelected]);

	useEffect(() => {
		setTabActiveKey("HALL_CALLS");
		setLiftVip({});
		setLocalSocketMessage(BLANK_SOCKET_MESSAGE);
	}, [openModalControlPanelBank]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='650'
			placement={controlPanelDrawerPlacement}
			open={openModalControlPanelBank}
			closable={false}
			onClose={onClickClose}
			footer={
				<div style={{ textAlign: "right" }}>
					<ButtonClose onClick={onClickClose} />
				</div>
			}
		>
			<div>
				<ControlPanelBankHeader liftVip={liftVip} />
			</div>
			<br />
			<div>
				<Tabs
					onTabClick={(key: string) => {
						setTabActiveKey(key);
					}}
					activeKey={tabActiveKey}
				>
					{validatePermission("monitor-control-panel-hall-calls", permissions) && (
						<Tabs.TabPane key='HALL_CALLS' tab='Hall Calls'>
							<ControlPanelBankHallCalls
								hasFrontDoors={displayFrontDoors}
								hasRearDoors={displayRearDoors}
								bankFloors={bankFloors}
								frontLock={[
									...localSocketMessage.hall_calls.up.front_lock,
									...localSocketMessage.hall_calls.down.front_lock,
								]}
								rearLock={[
									...localSocketMessage.hall_calls.up_assigned.rear_lock,
									...localSocketMessage.hall_calls.down_assigned.rear_lock,
								]}
							/>
						</Tabs.TabPane>
					)}
					{validatePermission("monitor-control-panel-vip-mode", permissions) && (
						<Tabs.TabPane key='VIP' tab='VIP Mode'>
							<ControlPanelBankVIP
								hasFrontDoors={displayFrontDoors}
								hasRearDoors={displayRearDoors}
								bankFloors={bankFloors}
								liftVip={liftVip}
								socketMessage={socketMessageVip}
								clearVipMode={clearVipMode}
								onClickClose={onClickClose}
								vipServiceModeStopped={vipServiceModeStopped}
							/>
						</Tabs.TabPane>
					)}
					{validatePermission("monitor-control-panel-faults", permissions) && (
						<Tabs.TabPane key='FAULTS' tab='Faults'>
							<ControlPanelBankFaults />
						</Tabs.TabPane>
					)}
				</Tabs>
			</div>
			<Modal title={t("general.warningCloseVipModal")} open={openModal} onOk={onClickOk} onCancel={onClickCancel}>
				<p>{t("general.warningCloseVipModalDescription")}</p>
			</Modal>
		</Drawer>
	);
}

export default ControlPanelBank;
