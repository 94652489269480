import React from "react";
// NAVIGATION
import { useNavigate } from "react-router-dom";
// ANT DESIGN COMPONENTS
import { Breadcrumb } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";

function KeysBreadcrumb() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const navigate = useNavigate();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const navigateToAdapters = () => {
		navigate({
			pathname: "/edge-management/adapters",
		});
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Breadcrumb className=''>
			<Breadcrumb.Item>{t("header.edgeManagement")}</Breadcrumb.Item>
			<Breadcrumb.Item
				onClick={() => {
					navigateToAdapters();
				}}
				className='generalStyles__breadcrumbItemInactive'
			>
				{t("header.adapters")}
			</Breadcrumb.Item>
			<Breadcrumb.Item className='generalStyles__breadcrumbItem'>Keys</Breadcrumb.Item>
		</Breadcrumb>
	);
}

export default KeysBreadcrumb;
