import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const maxRetries = 2;

const baseQuery = retry(
	fetchBaseQuery({
		baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE,
	}),
	{ maxRetries }
);

export const keyAPI = createApi({
	reducerPath: "keyAPI",
	baseQuery,
	keepUnusedDataFor: 0,
	tagTypes: ["tagGetAdapter", "tagGetKeys", "tagPostKey", "tagPutKey", "tagDeleteKey"],
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		//* *********************************************************** */
		//* GET ADAPTER */
		getAdapter: builder.query({
			query: (body: any) => ({
				url: `/adapters/${body.adapterId}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetAdapter"],
		}),

		//* *********************************************************** */
		//* GET KEYS */
		getKeys: builder.query({
			query: (body: any) => ({
				url: `/keys/?&page=1&limit=9999&filter_target=adapter_id&filter=${body.adapterId}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetKeys"],
		}),
		//* *********************************************************** */
		//* POST KEY */
		postKey: builder.mutation({
			query: (body: any) => ({
				url: `/keys/`,
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetKeys"],
		}),
		//* *********************************************************** */
		//* IMPORT KEY */
		importKey: builder.mutation({
			query: (body: any) => {
				const bodyFormData = new FormData();
				bodyFormData.append("csv", body.body.csv);
				bodyFormData.append("adapterID", body.body.adapter_id);
				return {
					url: `/keys/import/`,
					method: "post",
					body: bodyFormData,
					headers: { authorization: body.token },
					formData: true,
				};
			},
			invalidatesTags: ["tagGetKeys"],
		}),
		//* *********************************************************** */
		//* PUT KEY */
		putKey: builder.mutation({
			query: (body: any) => ({
				url: `/keys/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetKeys"],
		}),
		//* *********************************************************** */
		//* DELETE KEY */
		deleteKey: builder.mutation({
			query: (body: any) => ({
				url: `/keys/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetAdapter"],
		}),
		//* *********************************************************** */
	}),
});

export const {
	useLazyGetAdapterQuery,
	useLazyGetKeysQuery,
	usePostKeyMutation,
	useImportKeyMutation,
	usePutKeyMutation,
	useDeleteKeyMutation,
} = keyAPI;
