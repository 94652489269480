import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const maxRetries = 2;

const baseQuery = retry(
	fetchBaseQuery({
		baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE,
	}),
	{ maxRetries }
);

export const adapterAPI = createApi({
	reducerPath: "adapterAPI",
	baseQuery,
	keepUnusedDataFor: 0,
	tagTypes: ["tagGetAdapters"],
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		//* *********************************************************** */
		//* GET ALL ADAPTERS */
		getAdapters: builder.query({
			query: (body: any) => ({
				url: `/adapters/?page=${body.page}&limit=${body.limit}${body.searchAndFilterString || ""}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetAdapters"],
		}),
		//* *********************************************************** */
		//* CREATE ADAPTER */
		postAdapter: builder.mutation({
			query: (body: any) => ({
				url: `/adapters/`,
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetAdapters"],
		}),
		//* *********************************************************** */
		//* DELETE ADAPTERS */
		deleteAdapter: builder.mutation({
			query: (body: any) => ({
				url: `/adapters/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetAdapters"],
		}),
		//* *********************************************************** */
		//* EDIT ADAPTER */
		putAdapter: builder.mutation({
			query: (body: any) => ({
				url: `/adapters/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetAdapters"],
		}),
		//* *********************************************************** */
		//* CREATE ADAPTER */
		putAssignAction: builder.mutation({
			query: (body: any) => ({
				url: `/adapters/actions/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetAdapters"],
		}),
	}),
});

export const {
	useLazyGetAdaptersQuery,
	usePostAdapterMutation,
	useDeleteAdapterMutation,
	usePutAdapterMutation,
	usePutAssignActionMutation,
} = adapterAPI;
