import React from "react";
// ANT DESIGN COMPONENTS
import { useDispatch, useSelector } from "react-redux";
import { MapViewDirections, MapViewTypes } from "../../../interfaces/MapView.interface";
import { RootState } from "../../../app/store";
import { updateMapViewItem } from "../../../redux/mapView/mapViewSlice";
import {
	updateLiftSelected,
	updateLayoutSelected,
	updateModalControlPanelTab,
	updateExistsFaultsInLiftSelected,
	updatePanelDefaultState,
	updateExistsFaultsInMonitoringView,
	updateOpenModalControlPanel,
	updateControlPanelDrawerPlacement,
	updatePanelDefaultStateFaults,
} from "../../../redux/monitoring/monitoringSlice";
import MonitoringMapViewPin3 from "./MonitoringMapViewPin3";
import { BankFloorInterface, SocketMessageInterface } from "../../../interfaces/EdgeManagement.interface";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";

function MonitoringMapViewWrapper({
	id,
	direction,
	text,
	x,
	y,
	type,
	lastSocketMessage,
	layouts,
	faults,
}: {
	id: string;
	direction: MapViewDirections;
	text: string;
	x: number;
	y: number;
	type: MapViewTypes;
	lastSocketMessage: any;
	layouts: any;
	faults: any[];
}) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [lastShortMessage, setLastShortMessage] = React.useState<string>("elClose");
	const [carDirection, setCarDirection] = React.useState<string>("");
	const [liftSocketMessage, setLiftSocketMessage] = React.useState<any>();
	const [zIndex, setZIndex] = React.useState(0);
	const [serviceMode, setServiceMode] = React.useState("");
	const [hasFaults, setHasFaults] = React.useState(false);
	const [faultMessage, setFaultMessage] = React.useState<boolean>(false);
	const [bankFloors, setBankFloors] = React.useState([]);
	const [isVertical, setIsVertical] = React.useState(false);
	const [currentPositionNameFloor, setCurrentPositionNameFloor] = React.useState("");
	const [nextStopNameFloor, setNextStopNameFloor] = React.useState("");
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { mapViewArray } = useSelector((state: any) => state.mapView);
	const { elevatorSVGIcons, existsFaultsInMonitoringView, iconDefinitionElevator } = useSelector(
		(state: RootState) => state.monitoring
	);
	const { permissions } = useSelector((state: any) => state.user);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getTabKey = () => {
		const key = "CARCALLS";
		if (hasFaults || faultMessage) {
			return "FAULTS";
		}
		if (validatePermission("monitor-control-panel-car-calls", permissions)) {
			return "CARCALLS";
		}
		if (validatePermission("monitor-control-panel-hall-calls", permissions)) {
			return "HALLCALLS";
		}
		if (validatePermission("monitor-control-panel-actions", permissions)) {
			return "ACTIONS";
		}
		if (validatePermission("monitor-control-panel-faults", permissions)) {
			return "FAULTS";
		}
		if (validatePermission("monitor-control-panel-status", permissions)) {
			return "STATUS";
		}
		return key;
	};

	const getLayoutAndLift = () => {
		const selectedLayout = layouts.find((layout: any) => layout?.lifts.find((lift_: any) => lift_.id === id));
		const selectedLift = selectedLayout?.lifts.find((lift_: any) => lift_.id === id);
		return { selectedLayout, selectedLift };
	};

	const getDrawerPlacement = (e: any) => {
		const clickX = e.clientX;
		const screenWidth = window.innerWidth;
		if (clickX > screenWidth / 2) {
			dispatch(updateControlPanelDrawerPlacement("left"));
		} else {
			dispatch(updateControlPanelDrawerPlacement("right"));
		}
	};

	const onClickOpenModalElevator = (e: any) => {
		getDrawerPlacement(e);
		const { selectedLayout, selectedLift } = getLayoutAndLift();
		if (!selectedLayout || !selectedLift) return;
		dispatch(updateLiftSelected(selectedLift));
		dispatch(updateLayoutSelected(selectedLayout));
		dispatch(
			updateModalControlPanelTab({
				liftType: "ELEVATOR",
				tab: getTabKey(),
			})
		);
		dispatch(updateOpenModalControlPanel(true));
		dispatch(updateExistsFaultsInLiftSelected(hasFaults));
		dispatch(updatePanelDefaultState(lastSocketMessage));
		dispatch(updatePanelDefaultStateFaults(hasFaults));
	};

	const handleClick = (e: any) => {
		if (type === MapViewTypes.MAP) {
			// get the selected map view data
			const selectedMapView = mapViewArray.find((mapView: any) => mapView.id === id);
			if (selectedMapView) {
				// update my element
				dispatch(updateMapViewItem(selectedMapView));
			}
		} else {
			onClickOpenModalElevator(e);
		}
	};

	const getDirection: () => "top" | "bottom" | "left" | "right" = () => {
		if (direction === MapViewDirections.UP) return "top";
		if (direction === MapViewDirections.DOWN) return "bottom";
		if (direction === MapViewDirections.LEFT) return "left";
		if (direction === MapViewDirections.RIGHT) return "right";
		return "top";
	};

	const getTypeInLowerCase: () => "elevator" | "escalator" | "movingwalk" | "map" = () => {
		if (type === MapViewTypes.LIFT) return "elevator";
		if (type === MapViewTypes.ESCALATOR) return "escalator";
		if (type === MapViewTypes.MOVINGWALK) return "movingwalk";
		if (type === MapViewTypes.MAP) return "map";
		return "elevator";
	};

	const getBankFloors = () => {
		layouts.map((item: any) => {
			const foundLiftIndex = item?.lifts.findIndex((lift_: any) => lift_.id === id);
			if (foundLiftIndex >= 0) {
				setBankFloors(item.bank_floors);
			}
			return true;
		});
	};

	const getFloorName = (socketMessage_: SocketMessageInterface) => {
		bankFloors.map((floor: BankFloorInterface) => {
			if (socketMessage_.position === floor.floor_no) {
				setCurrentPositionNameFloor(floor.name);
			}
			if (
				socketMessage_.car_calls &&
				(socketMessage_.car_calls.front[0] || socketMessage_.car_calls.rear[0]) &&
				(socketMessage_.car_calls.front[0] === floor.floor_no || socketMessage_.car_calls.rear[0] === floor.floor_no)
			) {
				setNextStopNameFloor(floor.name);
			}
			return true;
		});
	};

	const getShortMessage = (socketMessage_: SocketMessageInterface) => {
		if (
			socketMessage_ &&
			socketMessage_.icon &&
			socketMessage_.icon.name &&
			socketMessage_.lift_type &&
			iconDefinitionElevator
		) {
			if (socketMessage_.lift_type === "ELEVATOR") {
				if (elevatorSVGIcons.includes(iconDefinitionElevator[socketMessage_.icon.name.toLowerCase()])) {
					setLastShortMessage(iconDefinitionElevator[socketMessage_.icon.name.toLowerCase()]);
				}
			} else if (socketMessage_.lift_type === "ESCALATOR" || socketMessage_.lift_type === "MOVINGWALK") {
				setLastShortMessage(socketMessage_.icon.name);
				setCarDirection(socketMessage_.direction);
			}
		}
	};

	const getServiceMode = (socketMessage_: SocketMessageInterface) => {
		if (socketMessage_.service_mode && socketMessage_.service_mode.name && socketMessage_.lift_id === id) {
			setServiceMode(socketMessage_.service_mode.name);
		}
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	React.useEffect(() => {
		if (lastSocketMessage && lastSocketMessage.lift_id === id) {
			setLiftSocketMessage(lastSocketMessage);
		}
	}, [lastSocketMessage]);

	React.useEffect(() => {
		getBankFloors();
	}, []);

	React.useEffect(() => {
		if (liftSocketMessage) {
			getFloorName(liftSocketMessage);
			getShortMessage(liftSocketMessage);
			getServiceMode(liftSocketMessage);
		}
	}, [liftSocketMessage]);

	React.useEffect(() => {
		if (direction === MapViewDirections.UP || direction === MapViewDirections.DOWN) {
			setIsVertical(true);
		} else if (direction === MapViewDirections.LEFT || direction === MapViewDirections.RIGHT) {
			setIsVertical(false);
		}
	}, [direction]);

	React.useEffect(() => {
		if (faults.length > 0) {
			faults.map((fault) => {
				if (fault.lift_id === id) {
					setFaultMessage(true);
					setHasFaults(true);
					dispatch(updateExistsFaultsInMonitoringView(true));
				}
				setTimeout(() => {
					setFaultMessage(false);
				}, 1000 * 60 * 60);
				return true;
			});
		}
	}, [faults]);

	React.useEffect(() => {
		if (!existsFaultsInMonitoringView) {
			setHasFaults(false);
			setFaultMessage(false);
			dispatch(updateExistsFaultsInMonitoringView(false));
		}
	}, [existsFaultsInMonitoringView]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div
			style={{
				position: "absolute",
				top: `${y}px`,
				left: `${x}px`,
				zIndex,
			}}
			onClick={handleClick}
			onMouseEnter={() => setZIndex(99999)}
			onMouseLeave={() => setZIndex(0)}
			role='button'
			tabIndex={0}
			aria-label='map view wrapper'
			onKeyDown={handleClick}
		>
			<MonitoringMapViewPin3
				carName={text}
				firstStop={currentPositionNameFloor}
				secondStop={nextStopNameFloor}
				lastShortMessage={lastShortMessage}
				carDirection={carDirection}
				serviceMode={serviceMode}
				direction={getDirection()}
				orientation={isVertical ? "vertical" : "horizontal"}
				fault={hasFaults}
				liftType={getTypeInLowerCase()}
				lift={getLayoutAndLift().selectedLift}
			/>
		</div>
	);
}

export default MonitoringMapViewWrapper;
