import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Spin, Input, Button, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { faCode, faEye, faPlugCirclePlus, faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerAssignInterface, updateAdapter } from "../../../redux/adapters/adapterSlice";
import { usePutAdapterMutation } from "../../../redux/adapters/adapterAPI";
// INTERFACES
import { RootState } from "../../../app/store";
import { AdapterInterface } from "../../../interfaces/Adapter.interface";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import DrawerRowDivider from "../../components/drawer/DrawerRowDivider";
import DrawerRowItem from "../../components/drawer/DrawerRowItem";
import TableColumnLiftType from "../../components/table/TableColumnLiftType";
import ButtonClose from "../../components/buttons/ButtonClose";
import ButtonSave from "../../components/buttons/ButtonSave";
import GlobalAlert2 from "../../home/GlobalAlert2";

function AdaptersAssignInterface() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const BLANK_ADAPTER: AdapterInterface = {
		auth_type: "PASSWORD",
		dictionary: {},
		interface: {},
		bacnet_config: {},
		lift_type: "ELEVATOR",
		name: "",
		socket_address: "127.0.0.1:1234",
		type: "WEBSOCKET",
		protocol_type: "",
		version: "",
		auth_credentials: { username: "", password: "" },
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [key, setKey] = useState<string>("");
	const [keys, setKeys] = useState<string[]>([]);
	const [jsonInterface, setJsonInterface] = useState<any>({});
	const [duplicatedKey, setDuplicatedKey] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openDrawerAssignInterface, adapter } = useSelector((state: RootState) => state.adapter);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerPutAdapter,
		{
			isLoading: isLoadingPutAdapter,
			isSuccess: isSuccessPutAdapter,
			isError: isErrorPutAdapter,
			error: errorPutAdapter,
			reset: resetPutAdapter,
		},
	] = usePutAdapterMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const activateLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		const copy = structuredClone(adapter);
		copy.interface = jsonInterface;
		triggerPutAdapter({ id: adapter.id, token, body: copy });
	};

	const onClickClose = () => {
		dispatch(updateOpenDrawerAssignInterface(false));
		resetPutAdapter();
		setKey("");
		setKeys([]);
		setJsonInterface({});
		setDuplicatedKey(false);
		dispatch(updateAdapter(BLANK_ADAPTER));
	};

	const inputOnChange = (newValue: string) => {
		setKey(newValue);
	};

	const disabledAddButton = () => {
		if (key !== "" && !duplicatedKey) {
			return false;
		}
		return true;
	};

	const onClickAddNewKey = () => {
		if (key !== "") {
			setKey("");
			const copy: string[] = structuredClone(keys);
			copy.push(key.replace(" ", "_"));
			setKeys(copy);
		}
	};

	const onKeyDownAddNewKey = async (event: any) => {
		if (event.key === "Enter" && !duplicatedKey) {
			onClickAddNewKey();
		}
	};

	const onClickDeleteKey = (deleteValue: string) => {
		const copy = structuredClone(keys);
		const index = copy.findIndex((item: string) => item === deleteValue);
		if (index >= 0) {
			copy.splice(index, 1);
		}
		setKeys(copy);
	};

	const generateJsonInterface = () => {
		const newObject: any = {};
		keys.forEach((item: string) => {
			newObject[item] = "";
		});
		setJsonInterface(newObject);
	};

	const initValues = () => {
		activateLoading();
		if (
			adapter &&
			typeof adapter.interface === "object" &&
			adapter.interface !== null &&
			!Array.isArray(adapter.interface)
		) {
			setKeys(Object.keys(adapter.interface));
		}
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (openDrawerAssignInterface) {
			initValues();
		} else {
			onClickClose();
		}
	}, [openDrawerAssignInterface]);

	useEffect(() => {
		generateJsonInterface();
	}, [keys]);

	useEffect(() => {
		if (keys.includes(key)) {
			setDuplicatedKey(true);
		} else {
			setDuplicatedKey(false);
		}
	}, [key]);

	useEffect(() => {
		if (isErrorPutAdapter || isSuccessPutAdapter) {
			onClickClose();
		}
	}, [isErrorPutAdapter, isSuccessPutAdapter]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='600'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openDrawerAssignInterface}
			footer={
				<div className='container generalStyles__flex mt-4'>
					<div className='generalStyles__width100 generalStyles__mrFix'>
						<ButtonClose onClick={onClickClose} />
					</div>
					<div className='generalStyles__width100 generalStyles__mlFix'>
						<ButtonSave onClick={onClickSave} disabledButton={keys.length === 0} />
					</div>
				</div>
			}
		>
			<Spin spinning={isLoadingPutAdapter || isLoading}>
				{/** ------------------------------------------------------------- */}
				{/** COMPONENT DRAWER DIVIDER */}
				<DrawerRowDivider icon={faPlugCirclePlus} title={t("adapter.adapterInformation")} />
				{/** ------------------------------------------------------------- */}
				{/** ADAPTER INFORMATION */}
				<div className={`drawer__box__${theme}`}>
					{/* ID */}
					<DrawerRowItem title='ID' value={adapter && adapter.id ? adapter.id : "--"} />
					{/* NAME */}
					<DrawerRowItem title={t("general.name")} value={adapter && adapter.name ? adapter.name : "--"} />
					{/* VERSION */}
					<DrawerRowItem title={t("adapter.version")} value={adapter && adapter.version ? adapter.version : "--"} />
					{/* SOCKET ADDRESS */}
					<DrawerRowItem
						title={t("edgeManagement.channels.socketAddress")}
						value={adapter && adapter.socket_address ? adapter.socket_address : "--"}
					/>
					{/* LIFT TYPE */}
					<div className='generalStyles__flex'>
						<div>
							<p className='drawer__title'>Lift {t("edgeManagement.lifts.type")}</p>
						</div>
						<div className='drawer__textContainer'>
							<div className='drawer__text'>
								{adapter && adapter.lift_type ? <TableColumnLiftType liftType={adapter.lift_type || ""} /> : "--"}
							</div>
						</div>
					</div>
				</div>
				<br />
				{/** ------------------------------------------------------------- */}
				{/** COMPONENT DRAWER DIVIDER */}
				<DrawerRowDivider icon={faCode} title={t("general.interfaceAssign")} />
				<div className={`drawer__box__${theme}`}>
					<div className='generalStyles__flex'>
						<div>
							<p className='drawer__title'>Key:</p>
						</div>
						<div className='drawer__textContainer generalStyles__width100'>
							<div className='generalStyles__flex mb-2'>
								<div className='generalStyles__width100'>
									<Input
										className='generalStyles__input'
										size='small'
										value={key}
										onKeyDown={onKeyDownAddNewKey}
										onChange={(event: React.FormEvent<HTMLInputElement>) => {
											inputOnChange(event.currentTarget.value);
										}}
										status={!keys.includes(key) ? "" : "error"}
									/>
									{keys.includes(key) && (
										<span className='generalStyles__error' style={{ fontSize: "8px" }}>
											{t("general.keyAlreadyExist")}
										</span>
									)}
								</div>
								<div className='generalStyles__mlFix'>
									<Tooltip title={t("general.add")} placement='left'>
										<Button
											className='generalStyles__mrFix'
											shape='circle'
											icon={<FontAwesomeIcon icon={faPlusCircle} />}
											disabled={disabledAddButton()}
											onClick={onClickAddNewKey}
										/>
									</Tooltip>
								</div>
							</div>
							{keys.map((item: string) => (
								<div className='generalStyles__flex mt-1'>
									<div className='generalStyles__width100'>
										<Input className='generalStyles__input' size='small' value={item} disabled />
									</div>
									<div className='generalStyles__mlFix'>
										<Tooltip title={t("general.delete")} placement='left'>
											<Button
												style={{ marginTop: "-3px" }}
												className='generalStyles__mrFix buttonStyle__26'
												shape='circle'
												icon={<FontAwesomeIcon icon={faTrash} />}
												onClick={() => {
													onClickDeleteKey(item);
												}}
											/>
										</Tooltip>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<br />
				{/** ------------------------------------------------------------- */}
				{/** COMPONENT DRAWER DIVIDER */}
				<DrawerRowDivider icon={faEye} title={t("general.preview")} />
				<div className={`drawer__box__${theme}`}>
					<div className='generalStyles__flex drawer__container'>
						<div>
							<pre className='drawer__text'>{JSON.stringify(jsonInterface, null, 2)}</pre>
						</div>
					</div>
				</div>
			</Spin>
			<GlobalAlert2
				isError={isErrorPutAdapter}
				isSuccess={isSuccessPutAdapter}
				requestType='PUT'
				error={errorPutAdapter}
				name='Adapter'
			/>
		</Drawer>
	);
}

export default AdaptersAssignInterface;
