import { useEffect } from "react";

const MonitoringAppFocus = (callback: () => void) => {
	useEffect(() => {
		// Event handler for when the app gains focus
		const handleFocus = () => {
			// eslint-disable-next-line no-console
			console.info("App is focused");
			callback();
		};

		// Add event listener
		window.addEventListener("focus", handleFocus);

		// Cleanup event listener on component unmount
		return () => window.removeEventListener("focus", handleFocus);
	}, [callback]);
};
export default MonitoringAppFocus;
