/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// INTERFACES
import { MenuInterface, UserInterface } from "../../interfaces/User.interface";

export interface UserState {
	user: UserInterface;
	openModalCreateEditUser: boolean;
	openModalDeleteUser: boolean;
	openModalResetUserPassword: boolean;
	openModalBlockUnBlockUser: boolean;
	openDrawerUser: boolean;
	userRefreshTable: boolean;
	permissions: MenuInterface;
	openModalPsw: boolean;
	formCreateEdit: string;
}

const initialState: UserState = {
	user: {
		first_name: "",
		middle_name: "",
		last_name: "",
		role_id: "",
		org_id: "",
		email: "",
		contact_info: [
			{
				id: "",
				address: "",
				phone: "",
				phone_code: "",
				priority: 0,
			},
		],
	},
	openModalCreateEditUser: false,
	openModalDeleteUser: false,
	openModalResetUserPassword: false,
	openModalBlockUnBlockUser: false,
	openDrawerUser: false,
	userRefreshTable: false,
	permissions: {
		name: "",
		active: false,
		offline: false,
		pages: [],
	},
	openModalPsw: false,
	formCreateEdit: "create",
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		updateUser: (state, action: PayloadAction<UserInterface>) => {
			state.user = action.payload;
		},
		updateOpenModalCreateEditUser: (state, action: PayloadAction<boolean>) => {
			state.openModalCreateEditUser = action.payload;
		},
		updateOpenModalDeleteUser: (state, action: PayloadAction<boolean>) => {
			state.openModalDeleteUser = action.payload;
		},
		updateOpenModalResetUserPassword: (state, action: PayloadAction<boolean>) => {
			state.openModalResetUserPassword = action.payload;
		},
		updateOpenModalBlockUnBlockUser: (state, action: PayloadAction<boolean>) => {
			state.openModalBlockUnBlockUser = action.payload;
		},
		updateOpenDrawerUser: (state, action: PayloadAction<boolean>) => {
			state.openDrawerUser = action.payload;
		},
		updateUserRefreshTable: (state, action: PayloadAction<boolean>) => {
			state.userRefreshTable = action.payload;
		},
		updatePermissions: (state, action: PayloadAction<MenuInterface>) => {
			state.permissions = action.payload;
		},
		updateOpenModalPsw: (state, action: PayloadAction<boolean>) => {
			state.openModalPsw = action.payload;
		},
		updateFormCreateEdit: (state, action: PayloadAction<string>) => {
			state.formCreateEdit = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateUser,
	updateOpenModalCreateEditUser,
	updateOpenModalDeleteUser,
	updateOpenModalResetUserPassword,
	updateOpenModalBlockUnBlockUser,
	updateOpenDrawerUser,
	updateUserRefreshTable,
	updatePermissions,
	updateOpenModalPsw,
	updateFormCreateEdit,
} = userSlice.actions;

export default userSlice.reducer;
