import React from "react";
// ANT DESIGN COMPONENTS
// REDUX
import { useSelector } from "react-redux";
// INTERFACES
import { RootState } from "../../../app/store";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";
// COMPONENTS
import KeysHeader from "./KeysHeader";
import KeysTable from "./KeysTable";

function KeysPage() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div className={`layout__contentPage__${theme}`}>
				{validatePermission("permission-not-defined", permissions) ? (
					<div>
						<KeysHeader />
						<KeysTable />
					</div>
				) : (
					<UnauthorizedPage />
				)}
			</div>
		</div>
	);
}

export default KeysPage;
