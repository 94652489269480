import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Empty, Table, Tag } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetAdaptersQuery } from "../../../redux/adapters/adapterAPI";
import { updateAdapter, updateOpenDrawerAdapter } from "../../../redux/adapters/adapterSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import validatePermission from "../../../utils/validatePermissions";
// FILTERS
import FilterSearchText from "../../components/filters/FilterSearchText";
import FilterSelectLiftType from "../../components/filters/FilterSelectLiftType";
import FilterSelectProtocolType from "../../components/filters/FilterSelectProtocolType";
import FilterSelectAuthType from "../../components/filters/FilterSelectAuthType";
import { buildFilterString, getTableRowClass } from "../../../utils/utils";
// COMPONENTS
import TableNoDataBox from "../../components/table/TableNoDataBox";
import TableColumnLiftType from "../../components/table/TableColumnLiftType";
import TableColumnProtocolType from "../../components/table/TableColumnProtocolType";
import TableColumnAuthType from "../../components/table/TableColumnAuthType";
import AdaptersTableActions from "./AdaptersTableActions";
// INTERFACES
import { AdapterInterface } from "../../../interfaces/Adapter.interface";
import { TablePaginationInterface } from "../../../interfaces/Global.interface";
import { RootState } from "../../../app/store";

function AdaptersTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { theme } = useSelector((state: RootState) => state.home);
	const { Column } = Table;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [adapters, setAdapters] = useState<AdapterInterface[]>([]);
	const [filterName, setFilterName] = useState("");
	const [filterLiftType, setFilterLiftType] = useState("");
	const [filterAuthType, setFilterAuthType] = useState("");
	const [filterProtocolType, setFilterProtocolType] = useState("");
	const [errorMessage, setErrorMessage] = useState<any>("");
	const [pagination, setPagination] = useState<TablePaginationInterface>({
		current: 1,
		pageSize: 10,
		showSizeChanger: true,
		pageSizeOptions: ["10", "20", "50", "100"],
		total: 0,
	});
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { adapterRefreshTable } = useSelector((state: RootState) => state.adapter);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetAdaptersTable,
		{
			data: dataGetAdapters,
			isLoading: isLoadingGetAdapters,
			isError: isErrorGetAdapters,
			isFetching: isFetchingGetAdapters,
			error: errorGetAdapters,
		},
	] = useLazyGetAdaptersQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */

	const getData = async (page: number, limit: number) => {
		const searchAndFilterString = buildFilterString({
			filterName,
			filterLiftTypeFullName: filterLiftType,
			filterAuthType,
			filterProtocolType,
		});
		const token = await GETJwtToken();
		triggerGetAdaptersTable({
			page,
			limit,
			token,
			searchAndFilterString,
		});
	};

	const handleTableChange = async (pagination_: any) => {
		setPagination({
			...pagination,
			current: pagination_.current,
			pageSize: pagination_.pageSize,
			total: pagination_.total,
		});
		getData(pagination_.current, pagination_.pageSize);
	};

	const refreshTable = async () => {
		setFilterName("");
		setFilterLiftType("");
		setFilterAuthType("");
		setFilterProtocolType("");
		setPagination({
			...pagination,
			current: 1,
			pageSize: 10,
		});
		getData(1, 10);
	};

	const onCellClick = (record: AdapterInterface) => {
		if (record) {
			dispatch(updateAdapter(record));
			dispatch(updateOpenDrawerAdapter(true));
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		getData(1, 10);
	}, []);

	useEffect(() => {
		if (dataGetAdapters && dataGetAdapters.data) {
			setPagination({
				...pagination,
				current: pagination.current,
				pageSize: pagination.pageSize,
				total: dataGetAdapters.total,
			});
			setAdapters(dataGetAdapters.data);
		}
	}, [dataGetAdapters]);

	useEffect(() => {
		if (adapterRefreshTable) {
			refreshTable();
		}
	}, [adapterRefreshTable]);

	useEffect(() => {
		if (errorGetAdapters) {
			setErrorMessage(errorGetAdapters);
			setAdapters([]);
			setPagination({
				current: 1,
				pageSize: 10,
				showSizeChanger: true,
				pageSizeOptions: ["10", "20", "50", "100"],
				total: 0,
			});
		}
	}, [errorGetAdapters]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='mt-3'>
			{validatePermission("adapters-view", permissions) ? (
				<Table
					locale={{
						emptyText: (
							<TableNoDataBox
								isError={isErrorGetAdapters}
								errorMessage={
									errorMessage && errorMessage.data && errorMessage.data.message ? errorMessage.data.message : "Error"
								}
								noItemsFound='No adapters found'
							/>
						),
					}}
					rowClassName={(record, index) => getTableRowClass(index, theme)}
					className='customTable'
					dataSource={adapters}
					loading={isLoadingGetAdapters || isFetchingGetAdapters}
					rowKey={(record: any) => record.id}
					size='small'
					pagination={pagination}
					onChange={handleTableChange}
					scroll={{ x: 900 }}
					footer={() => (
						<div className='generalStyles__flexEnd'>
							<Tag>{pagination.total} Adapters</Tag>
						</div>
					)}
				>
					{/** NAME */}
					<Column
						onCell={(record: AdapterInterface) => ({
							onClick: () => {
								onCellClick(record);
							},
						})}
						title={t("adapter.name")}
						dataIndex='name'
						key='name'
						filterIcon={<FontAwesomeIcon icon={faSearch} />}
						filterDropdown={() => (
							<FilterSearchText
								placeHolder={t("general.name")}
								filterValue={filterName}
								setFilterValue={setFilterName}
								refreshTable={refreshTable}
								refreshParameters={[]}
							/>
						)}
						render={(text) => <div>{text}</div>}
					/>
					{/** LIFT TYPE -- */}
					<Column
						onCell={(record: AdapterInterface) => ({
							onClick: () => {
								onCellClick(record);
							},
						})}
						title={`Lift ${t("edgeManagement.lifts.type")}`}
						dataIndex='lift_type'
						key='lift_type'
						filterIcon={<FontAwesomeIcon icon={faSearch} />}
						filterDropdown={() => (
							<FilterSelectLiftType
								placeHolder={t("general.liftType")}
								setFilterValue={setFilterLiftType}
								filterValue={filterLiftType}
								refreshTable={refreshTable}
							/>
						)}
						render={(text) => <TableColumnLiftType liftType={text || ""} />}
					/>
					{/** PROTOCOL TYPE -- */}
					<Column
						onCell={(record: AdapterInterface) => ({
							onClick: () => {
								onCellClick(record);
							},
						})}
						title={t("general.protocol")}
						dataIndex='protocol_type'
						key='protocol_type'
						filterIcon={<FontAwesomeIcon icon={faSearch} />}
						filterDropdown={() => (
							<FilterSelectProtocolType
								placeHolder={t("general.protocol")}
								setFilterValue={setFilterProtocolType}
								filterValue={filterProtocolType}
								refreshTable={refreshTable}
							/>
						)}
						render={(text) => <TableColumnProtocolType protocolType={text || ""} />}
					/>
					{/** VERSION */}
					<Column
						onCell={(record: AdapterInterface) => ({
							onClick: () => {
								onCellClick(record);
							},
						})}
						title={t("adapter.version")}
						dataIndex='version'
						key='version'
						render={(text) => <div>{text || ""}</div>}
					/>
					{/** SOCKET ADDRESS */}
					<Column
						onCell={(record: AdapterInterface) => ({
							onClick: () => {
								onCellClick(record);
							},
						})}
						title={t("edgeManagement.channels.socketAddress")}
						dataIndex='socket_address'
						key='socket_address'
						render={(text) => <div>{text || "--"}</div>}
					/>
					{/** AUTH TYPE */}
					<Column
						onCell={(record: AdapterInterface) => ({
							onClick: () => {
								onCellClick(record);
							},
						})}
						title={t("adapter.authType")}
						dataIndex='auth_type'
						key='auth_type'
						filterIcon={<FontAwesomeIcon icon={faSearch} />}
						filterDropdown={() => (
							<FilterSelectAuthType
								placeHolder={t("adapter.authType")}
								setFilterValue={setFilterAuthType}
								filterValue={filterAuthType}
								refreshTable={refreshTable}
							/>
						)}
						render={(text) => <TableColumnAuthType authType={text || ""} />}
					/>
					{/** ACTIONS */}
					<Column
						title={t("adapter.actions")}
						key='action'
						render={(_: any, record: AdapterInterface) => <AdaptersTableActions selectedItem={record} />}
					/>
				</Table>
			) : (
				<>
					<div className='generalStyles__noAccessToListTable'>
						<Empty description={t("adapter.listNotAvailable")} image={Empty.PRESENTED_IMAGE_SIMPLE} />
					</div>
				</>
			)}
		</div>
	);
}

export default AdaptersTable;
