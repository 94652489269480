import React, { useEffect, useState } from "react";
// NAVIGATION
import { useLocation } from "react-router-dom";
// XLSX
import * as XLSX from "xlsx";
// MOMENT
import moment from "moment";
// ANT DESIGN COMPONENTS
import { Table, Tag, Button, InputNumber, Tooltip, Upload, message, Select } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faDownload, faUpload, faSave, faCircleCheck, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { messageAPI, useLazyGetMessagesQuery } from "../../../redux/messages/messagesAPI";
import {
	useLazyGetKeysQuery,
	useDeleteKeyMutation,
	useLazyGetAdapterQuery,
	usePostKeyMutation,
	usePutKeyMutation,
	keyAPI,
} from "../../../redux/keys/keysAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass, generateExcelAndDownloadDefinitions } from "../../../utils/utils";
// INTERFACES
import { RootState } from "../../../app/store";
import { MessageInterface } from "../../../interfaces/Message.interface";
// COMPONENTS
import TableNoDataBox from "../../components/table/TableNoDataBox";
// COMPONENTS
import GlobalAlert2 from "../../home/GlobalAlert2";
// UTILS

function KeysTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Column } = Table;
	const [t] = useTranslation("global");
	const location = useLocation();
	const adapterId = new URLSearchParams(location.search).get("idAdapter");
	const { Option } = Select;
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [totalRows, setTotalRows] = useState<number>(0);
	const [errorMessage, setErrorMessage] = useState<any>({});
	const [data, setData] = useState<any>([]);
	const [interfaceKeys, setInterfaceKeys] = useState<string[]>([]);
	const [adapter, setAdapter] = useState<any>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [offsetTypes, setOffsetTypes] = useState<string[]>([]);
	const [offsetTypeSelected, setOffsetTypeSelected] = useState<string>("ALL");

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetAdapter,
		{
			data: dataGetAdapter,
			isLoading: isLoadingGetAdapter,
			isFetching: isFetchingGetAdapter,
			isError: isErrorGetAdapter,
			error: errorGetAdapter,
		},
	] = useLazyGetAdapterQuery();

	const [
		triggerGetMessages,
		{
			isLoading: isLoadingGetMessages,
			isFetching: isFetchingGetMessages,
			isError: isErrorGetMessages,
			error: errorGetMessages,
		},
	] = useLazyGetMessagesQuery();

	const [
		triggerGetKeys,
		{
			data: dataGetKeys,
			isLoading: isLoadingGetKeys,
			isFetching: isFetchingKeys,
			isError: isErrorGetKeys,
			error: errorGetKeys,
		},
	] = useLazyGetKeysQuery();

	const [
		triggerDeleteKey,
		{ isLoading: isLoadingDeleteKey, isSuccess: isSuccessDeleteKey, error: errorDeleteKey, isError: isErrorDeleteKey },
	] = useDeleteKeyMutation();

	const [
		triggerPostKey,
		{
			isSuccess: isSuccessPostKey,
			isLoading: isLoadingPostKey,
			isError: isErrorPostKey,
			error: errorPostKey,
			reset: resetPostKey,
		},
	] = usePostKeyMutation();

	const [
		triggerPutKey,
		{
			isSuccess: isSuccessPutKey,
			isError: isErrorPutKey,
			error: errorPutKey,
			isLoading: isLoadingPutKey,
			reset: resetPutKey,
		},
	] = usePutKeyMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const launchIsLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	};

	const getKeysAssigned = async () => {
		const token = await GETJwtToken();
		triggerGetKeys({
			token,
			adapterId: adapter.id,
		});
	};

	const findDuplicateIndices = (checkData: any[], keys: string[]) => {
		const seen = new Map<string, number>();
		let copy: any = structuredClone(checkData);
		if (offsetTypeSelected !== "ALL") {
			copy = copy.filter((item: any) => item.type === offsetTypeSelected);
		}
		copy.forEach((item: any, index: any) => {
			const keyCombination = keys.map((key: string) => item[key]).join("|");
			let hasOnlyCeros = true;
			keys.map((key: string) => {
				if (item[key] !== 0 && item[key] !== "0") {
					hasOnlyCeros = false;
				}
				return true;
			});
			if (hasOnlyCeros) {
				copy[index].isValid = false;
			}
			if (seen.has(keyCombination)) {
				copy[index].isValid = false;
			} else {
				seen.set(keyCombination, index);
			}
		});
		setData(copy);
	};

	const replaceEmptyStrings = (obj: any) =>
		Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, value === "" ? 0 : value]));

	const buildFirstData = (adapterInterfaceKeys: any, messages: any) => {
		const newData: any = [];
		messages.map((msg: MessageInterface) => {
			newData.push({
				isValid: true,
				idKey: "",
				methodType: "POST",
				idMessage: msg.id,
				message: msg.short_message,
				type: msg.message_type.name,
				...replaceEmptyStrings(adapterInterfaceKeys),
			});
			return true;
		});
		getKeysAssigned();
		findDuplicateIndices(newData, interfaceKeys);
	};

	const getInitialData = async () => {
		if (adapterId && adapterId !== "") {
			const token = await GETJwtToken();
			dispatch(keyAPI.util.resetApiState());
			dispatch(messageAPI.util.resetApiState()); // Resets the state for all queries in the API slice

			await triggerGetAdapter({ token, adapterId }).unwrap();
		}
	};

	const buildOffsetTypes = (response: any) => {
		const newArr: any = [
			"ALL",
			...new Set(response.filter((item: any) => item?.message_type?.name).map((item: any) => item.message_type.name)),
		];
		setOffsetTypes(newArr);
	};

	const getMessagesAndKeysData = async () => {
		const token = await GETJwtToken(); // Wait for token
		try {
			const response = await triggerGetMessages({ token });
			if (response) {
				if (response && response.data && response.data.data) {
					setTotalRows(response.data.total);
					if (adapter && adapter.interface) {
						buildFirstData(adapter.interface, response.data.data);
						buildOffsetTypes(response.data.data);
						setInterfaceKeys(Object.keys(adapter.interface));
					}
				}
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error("Error fetching messages:", error);
		}
	};

	const onClickDelete = async (item: any) => {
		const token = await GETJwtToken();
		triggerDeleteKey({ id: item.idKey, token });
	};

	const onChangeInputNumber = (newValue: number, item: string, index: number) => {
		const copy = structuredClone(data);
		copy[index][item] = newValue;
		copy[index].isValid = true;
		findDuplicateIndices(copy, interfaceKeys);
	};

	const buildAssignedKeys = (keys: any) => {
		const copy = structuredClone(data);
		keys.map((item: any) => {
			const index = copy.findIndex((x: any) => x.idMessage === item.message_id);
			if (index >= 0) {
				copy[index].isValid = true;
				copy[index].idKey = item.id;
				copy[index].methodType = "PUT";
				Object.entries(item.value).map(([key, value]) => {
					copy[index][key] = value;
					return true;
				});
			}
			return true;
		});
		findDuplicateIndices(copy, interfaceKeys);
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		const requests = data.map((item: any) => {
			if (item.isValid) {
				const newValue = interfaceKeys.reduce((acc: any, key: any) => {
					acc[key] = item[key];
					return acc;
				}, {});
				const requestBody = {
					adapter_id: adapter.id,
					message_id: item.idMessage,
					value: JSON.stringify(newValue),
				};
				return item.methodType === "POST"
					? triggerPostKey({ token, body: requestBody })
					: triggerPutKey({ token, id: item.idKey, body: requestBody });
			}
			return true;
		});
		await Promise.all(requests);
	};

	const onClickDownloadExcel = () => {
		const currentDateTime = moment().format("YYYY_MMM_DD");
		const configurationName = adapter.name;
		// eslint-disable-next-line no-useless-escape
		const reportName = `definitions_${configurationName.replace(/[\/\\:\*\?"<>\|\s]/g, "_")}_${currentDateTime}`;
		const customAttributes: any = {
			idMessage: true,
			message: true,
			type: true,
			...Object.fromEntries(
				Object.entries(adapter.interface).map(([key, value]) => [key, value === "" ? true : value])
			),
		};
		// Remove all columns not used by attributes and undefined/null items
		const filteredData = data.map((item: any) =>
			Object.keys(item)
				.filter((key: any) => customAttributes[key])
				.reduce((acc: any, key: any) => {
					const value = item[key];
					return { ...acc, [key]: value };
				}, {})
		);
		// Convert boolean values into string
		const filteredData2 = filteredData.map((item: any) =>
			Object.keys(item).reduce((acc: any, key: any) => {
				// Check if the value is a boolean
				// eslint-disable-next-line no-nested-ternary
				acc[key] = typeof item[key] === "boolean" ? (item[key] ? "CLONE" : "") : item[key];
				return acc;
			}, {})
		);
		//
		generateExcelAndDownloadDefinitions(filteredData2, reportName);
	};

	const updateSpecificKeys = (arr1: any[], arr2: any[], keysToUpdate: string[]) =>
		arr1.map((obj1: any) => {
			const obj2: any = arr2.find((o) => o.idMessage === obj1.idMessage);
			if (obj2) {
				keysToUpdate.forEach((key) => {
					if (key in obj2) {
						// eslint-disable-next-line no-param-reassign
						obj1[key] = obj2[key].toString();
						// eslint-disable-next-line no-param-reassign
						obj1.isValid = true;
					}
				});
			}
			return obj1;
		});

	const handleUpload = (file: File) => {
		launchIsLoading();
		const reader = new FileReader();
		reader.onload = (e) => {
			try {
				const binaryStr = e.target?.result;
				const workbook = XLSX.read(binaryStr, { type: "binary" });
				const sheetName = workbook.SheetNames[0];
				const sheet = workbook.Sheets[sheetName];
				const jsonData = XLSX.utils.sheet_to_json(sheet);
				message.success("File uploaded and data processed successfully!");
				//
				const updatedObj1 = updateSpecificKeys(data, jsonData, interfaceKeys);
				findDuplicateIndices(updatedObj1, interfaceKeys);
				//
			} catch (error) {
				message.error("Error reading the Excel file. Please try again.");
			}
		};
		reader.readAsBinaryString(file); // Read the file as binary
		return false; // Prevent default upload behavior
	};

	const onChangeSelect = (newValue: string) => {
		setOffsetTypeSelected(newValue);
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		getInitialData();
		resetPostKey();
		resetPutKey();
	}, []);

	useEffect(() => {
		if (dataGetAdapter && dataGetAdapter.data) {
			setAdapter(dataGetAdapter.data);
		}
	}, [dataGetAdapter]);

	useEffect(() => {
		if (errorGetMessages || errorGetKeys || errorGetAdapter) {
			setErrorMessage(errorGetMessages || errorGetKeys || errorGetAdapter);
		}
	}, [errorGetMessages, errorGetKeys, errorGetAdapter]);

	useEffect(() => {
		if (dataGetKeys && dataGetKeys.data) {
			buildAssignedKeys(dataGetKeys.data);
		}
	}, [dataGetKeys]);

	useEffect(() => {
		if (adapter) {
			getMessagesAndKeysData();
		}
	}, [adapter]);

	useEffect(() => {
		getInitialData();
	}, [offsetTypeSelected]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div className='generalStyles__pageHeader' style={{ display: "flex", justifyContent: "space-between" }}>
				<div>
					<div className='generalStyles__flex'>
						{" "}
						<h5 className='generalStyles__info'>{adapter.name || "--"}</h5>
					</div>
				</div>
				<div className='generalStyles__flex '>
					<div className=' generalStyles__width100'>
						<Select
							className='generalStyles__width250px'
							size='small'
							value={offsetTypeSelected}
							onChange={onChangeSelect}
						>
							{offsetTypes.map((item: string) => (
								<Option key={item} value={item}>
									{item}
								</Option>
							))}
						</Select>
					</div>
					<div>
						<Tooltip title={t("general.downloadFile")}>
							<Button
								className='buttonStyle__13 generalStyles__mlFix'
								style={{ marginTop: "-5px" }}
								onClick={onClickDownloadExcel}
								icon={<FontAwesomeIcon className='' icon={faDownload} />}
							/>
						</Tooltip>
					</div>
					<div>
						<Upload accept='.xlsx, .xls' beforeUpload={handleUpload} showUploadList={false}>
							<Tooltip title={t("general.uploadFile")}>
								<Button
									className='buttonStyle__20 generalStyles__mlFix'
									style={{ marginTop: "-5px" }}
									icon={<FontAwesomeIcon className='' icon={faUpload} />}
								/>
							</Tooltip>
						</Upload>
					</div>
					<div>
						<Tooltip title={t("general.save")}>
							<Button
								icon={<FontAwesomeIcon className='' icon={faSave} />}
								className='buttonStyle__17 generalStyles__mlFix'
								style={{ marginTop: "-5px" }}
								onClick={onClickSave}
							/>
						</Tooltip>
					</div>
				</div>
			</div>
			<Table
				locale={{
					emptyText: (
						<TableNoDataBox
							isError={isErrorGetMessages || isErrorGetKeys || isErrorGetAdapter}
							errorMessage={
								errorMessage && errorMessage.data && errorMessage.data.message ? errorMessage.data.message : "Error"
							}
							noItemsFound='No actions found'
						/>
					),
				}}
				rowClassName={(record, index) => getTableRowClass(index, theme)}
				dataSource={data}
				pagination={{
					defaultPageSize: 10,
					showSizeChanger: true,
					pageSizeOptions: ["10", "25", "50", "100"],
				}}
				rowKey={(record) => record.idMessage}
				size='small'
				className='mt-3'
				loading={
					isFetchingGetMessages ||
					isLoadingGetMessages ||
					isFetchingKeys ||
					isLoadingGetKeys ||
					isLoadingDeleteKey ||
					isFetchingGetAdapter ||
					isLoadingGetAdapter ||
					isLoadingPostKey ||
					isLoadingPutKey ||
					isLoading
				}
				footer={() => (
					<div className='generalStyles__flexEnd'>
						<Tag>{totalRows} Rows</Tag>
					</div>
				)}
			>
				<Column
					title={t("general.state")}
					dataIndex='state'
					key='state'
					align='center'
					render={(text, record: any) => (
						<>
							{record.isValid ? (
								<FontAwesomeIcon className='generalStyles__success' icon={faCircleCheck} />
							) : (
								<Tooltip title={t("general.keyDuplicated")}>
									<FontAwesomeIcon className='generalStyles__error' icon={faXmarkCircle} />
								</Tooltip>
							)}
						</>
					)}
				/>
				<Column title={t("general.message")} dataIndex='message' key='message' render={(text) => <>{text}</>} />
				<Column title={t("general.type")} dataIndex='type' key='type' render={(text) => <>{text}</>} />
				{interfaceKeys.map((item: string) => (
					<Column
						title={item}
						dataIndex={item}
						key={item}
						render={(text, record: any, index: number) => (
							<InputNumber
								bordered={false}
								className='configuration__inputSizeS'
								min={0}
								max={999}
								precision={0}
								value={record[item]}
								onChange={(e) => {
									onChangeInputNumber(e, item, index);
								}}
							/>
						)}
					/>
				))}
				{/** DELETE */}
				<Column
					title='Delete'
					dataIndex='delete'
					key='delete'
					render={(text, record: any) => (
						<Button
							onClick={() => {
								onClickDelete(record);
							}}
							shape='circle'
							className={!record.isValid ? "" : "buttonStyle__26"}
							disabled={!record.isValid}
						>
							<FontAwesomeIcon icon={faTrash} />
						</Button>
					)}
				/>
			</Table>
			<GlobalAlert2
				isError={isErrorDeleteKey}
				isSuccess={isSuccessDeleteKey}
				requestType='DELETE'
				error={errorDeleteKey}
				name='Key'
			/>
			<GlobalAlert2
				isError={isErrorPostKey}
				isSuccess={isSuccessPostKey}
				requestType='POST'
				error={errorPostKey}
				name='User'
			/>
			<GlobalAlert2
				isError={isErrorPutKey}
				isSuccess={isSuccessPutKey}
				requestType='PUT'
				error={errorPutKey}
				name='User'
			/>
		</div>
	);
}

export default KeysTable;
