import React from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import AdaptersHeader from "./AdaptersHeader";
import AdaptersCreateEditForm from "./AdaptersCreateEditForm";
import AdaptersDeleteModal from "./AdaptersDeleteModal";
import AdaptersDrawer from "./AdaptersDrawer";
import AdaptersTable from "./AdaptersTable";
import AdaptersAssignActions from "./AdaptersAssignActions";
import AdaptersAssignInterface from "./AdaptersAssignInterface";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";
// INTERFACES
import { RootState } from "../../../app/store";

function AdaptersPage() {
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{
				// eslint-disable-next-line no-nested-ternary
				validatePermission("view-edge-management-adapters", permissions) ? (
					<div className={`layout__contentPage__${theme}`}>
						<AdaptersHeader />
						<AdaptersTable />
						<AdaptersCreateEditForm />
						<AdaptersDeleteModal />
						<AdaptersDrawer />
						<AdaptersAssignActions />
						<AdaptersAssignInterface />
					</div>
				) : (
					<UnauthorizedPage />
				)
			}
		</div>
	);
}

export default AdaptersPage;
