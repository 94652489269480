import React from "react";
// ANT DESIGN COMPONENTS
import { Tag } from "antd";

function TableColumnBankActionType({ actionType }: { actionType: string }) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{actionType === "TOGGLE" && (
				<Tag color='blue' className='drawer__typeTag'>
					{actionType}
				</Tag>
			)}
			{actionType === "TOGGLETIMER" && (
				<Tag color='orange' className='drawer__typeTag'>
					{actionType}
				</Tag>
			)}
			{actionType === "HOLDBACK" && (
				<Tag color='green' className='drawer__typeTag'>
					{actionType}
				</Tag>
			)}{" "}
			{actionType === "TURNBACK" && (
				<Tag color='purple' className='drawer__typeTag'>
					{actionType}
				</Tag>
			)}{" "}
			{actionType === "ENERGIZER_TURN_BACK" && (
				<Tag color='volcano' className='drawer__typeTag'>
					ENERGIZE
				</Tag>
			)}{" "}
			{actionType === "" && (
				<Tag color='red' className='drawer__typeTag'>
					N/A
				</Tag>
			)}{" "}
		</div>
	);
}

export default TableColumnBankActionType;
