import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { faPlugCircleCheck } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenModalCreateNewAdapter, updateAdapter } from "../../../redux/adapters/adapterSlice";
import { usePostAdapterMutation, usePutAdapterMutation } from "../../../redux/adapters/adapterAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import FormInputText from "../../components/form/FormInputText";
import FormSelectLiftType from "../../components/form/FormSelectLiftType";
import FormSelectAdapterType from "../../components/form/FormSelectAdapterType";
import FormSelectAuthType from "../../components/form/FormSelectAuthType";
import FormInputIPandPort from "../../components/form/FormInputIPandPort";
import FormInputTextArea from "../../components/form/FormInputJsonTextArea";
import FormSelectProtocolType from "../../components/form/FormSelectProtocolType";
import DrawerCloseSaveButton from "../../components/drawer/DrawerCloseSaveButton";
import GlobalAlert2 from "../../home/GlobalAlert2";
import DrawerRowDivider from "../../components/drawer/DrawerRowDivider";
// INTERFACES
import { AdapterInterface } from "../../../interfaces/Adapter.interface";
import { RootState } from "../../../app/store";

function AdaptersCreateEditForm() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const BLANK_ADAPTER: AdapterInterface = {
		auth_type: "PASSWORD",
		dictionary: {},
		interface: {},
		bacnet_config: {},
		lift_type: "ELEVATOR",
		name: "",
		socket_address: "127.0.0.1:1234",
		type: "WEBSOCKET",
		protocol_type: "",
		version: "",
		auth_credentials: { username: "", password: "" },
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [adapter, setAdapter] = useState<AdapterInterface>(BLANK_ADAPTER);
	const [jsonStringDictionary, setJsonStringDictionary] = useState<string>("");
	const [jsonStringInterface, setJsonStringInterface] = useState<string>("");
	const [jsonStringBacnetConfig, setJsonStringBacnetConfig] = useState<string>("");

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const {
		openModalCreateNewAdapter,
		formCreateEdit,
		adapter: selectedAdapter,
	} = useSelector((state: RootState) => state.adapter);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerPostAdapter,
		{
			isLoading: isLoadingPostAdapter,
			isSuccess: isSuccessPostAdapter,
			isError: isErrorPostAdapter,
			error: errorPostAdapter,
			reset: resetPostAdapter,
		},
	] = usePostAdapterMutation();

	const [
		triggerPutAdapter,
		{
			isLoading: isLoadingPutAdapter,
			isSuccess: isSuccessPutAdapter,
			isError: isErrorPutAdapter,
			error: errorPutAdapter,
			reset: resetPutAdapter,
		},
	] = usePutAdapterMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		resetPostAdapter();
		resetPutAdapter();
		setAdapter(BLANK_ADAPTER);
		setJsonStringDictionary("");
		setJsonStringInterface("");
		setJsonStringBacnetConfig("");
		dispatch(updateOpenModalCreateNewAdapter(false));
		dispatch(updateAdapter(BLANK_ADAPTER));
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		if (formCreateEdit === "CREATE" || formCreateEdit === "DUPLICATE") {
			triggerPostAdapter({ token, body: adapter });
		} else {
			triggerPutAdapter({ id: selectedAdapter.id, token, body: adapter });
		}
	};

	const onChangeFormValue = (newValue: string, variableName: string) => {
		const copy: AdapterInterface = structuredClone(adapter);
		copy[variableName] = newValue;
		setAdapter(copy);
	};

	const onChangeFormValueAuthCredentials = (newValue: string, variableName: string) => {
		const copy: AdapterInterface = structuredClone(adapter);
		copy.auth_credentials[variableName] = newValue;
		setAdapter(copy);
	};

	const onChangeJsonString = (newValue: string, variableName: string) => {
		if (variableName === "dictionary") {
			setJsonStringDictionary(newValue);
		} else if (variableName === "bacnet_config") {
			setJsonStringBacnetConfig(newValue);
		} else if (variableName === "interface") {
			setJsonStringInterface(newValue);
		}
	};

	const checkFormIsValid = () => {
		let formIsValid = true;
		if (
			adapter.name === "" ||
			adapter.version === "" ||
			adapter.socket_address === "" ||
			adapter.protocol_type === "" ||
			(adapter.auth_credentials.password === "" && formCreateEdit === "CREATE") ||
			(adapter.auth_credentials.username === "" && formCreateEdit === "CREATE") ||
			(adapter.auth_credentials.password === "" && formCreateEdit === "DUPLICATE") ||
			(adapter.auth_credentials.username === "" && formCreateEdit === "DUPLICATE")
		) {
			formIsValid = false;
		}
		return formIsValid;
	};

	const isValidJSON = (str: string) => {
		try {
			JSON.parse(str);
			return true;
		} catch (e) {
			return false;
		}
	};

	const getTitle = () => {
		if (formCreateEdit === "CREATE") {
			return "adapter.createNewAdapter";
		}
		if (formCreateEdit === "EDIT") {
			return "adapter.editAdapter";
		}
		return "general.duplicateAdapter";
	};
	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		onClickClose();
	}, [isSuccessPostAdapter, isSuccessPutAdapter, isErrorPostAdapter, isErrorPutAdapter]);

	useEffect(() => {
		if (isValidJSON(jsonStringDictionary)) {
			const copy: AdapterInterface = structuredClone(adapter);
			copy.dictionary = JSON.parse(jsonStringDictionary);
			setAdapter(copy);
		}
	}, [jsonStringDictionary]);

	useEffect(() => {
		if (isValidJSON(jsonStringInterface)) {
			const copy: AdapterInterface = structuredClone(adapter);
			copy.interface = JSON.parse(jsonStringInterface);
			setAdapter(copy);
		}
	}, [jsonStringInterface]);

	useEffect(() => {
		if (isValidJSON(jsonStringBacnetConfig)) {
			const copy: AdapterInterface = structuredClone(adapter);
			copy.bacnet_config = JSON.parse(jsonStringBacnetConfig);
			setAdapter(copy);
		}
	}, [jsonStringBacnetConfig]);

	useEffect(() => {
		if ((formCreateEdit === "EDIT" || formCreateEdit === "DUPLICATE") && selectedAdapter) {
			setAdapter({
				auth_type: selectedAdapter.auth_type,
				dictionary: selectedAdapter.dictionary,
				interface: selectedAdapter.interface,
				bacnet_config: selectedAdapter.bacnet_config,
				lift_type: selectedAdapter.lift_type,
				name: selectedAdapter.name,
				socket_address: selectedAdapter.socket_address,
				type: selectedAdapter.type,
				version: selectedAdapter.version,
				protocol_type: selectedAdapter.protocol_type,
				auth_credentials: { username: "", password: "" },
			});
		}
		setJsonStringDictionary(JSON.stringify(selectedAdapter.dictionary));
		setJsonStringInterface(JSON.stringify(selectedAdapter.interface));
		setJsonStringBacnetConfig(JSON.stringify(selectedAdapter.bacnet_config));
	}, [selectedAdapter]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer width='600' placement='right' onClose={onClickClose} closable={false} open={openModalCreateNewAdapter}>
			<Spin spinning={isLoadingPostAdapter || isLoadingPutAdapter}>
				{/** ************************************************* */}
				{/** COMPONENT DRAWER DIVIDER */}
				<DrawerRowDivider icon={faPlugCircleCheck} title={t(getTitle())} />
				{/** ------------------------------------------------------------- */}
				{/** ADAPTER FORM  */}
				<div className='container'>
					<div className={`drawer__box__${theme} row`}>
						{/** COMPONENTS FORM - INPUT NAME */}
						<FormInputText
							inputValue={adapter.name}
							inputLabelName={t("general.name")}
							inputOnChange={onChangeFormValue}
							inputVariableName='name'
							isRequired
							disabledInput={false}
						/>
						{/** COMPONENTS FORM - SELECT LIFT TYPE */}
						<FormSelectLiftType
							inputValue={adapter.lift_type}
							inputOnChange={onChangeFormValue}
							inputLabelName='Lift Type'
							inputVariableName='lift_type'
						/>
						{/** COMPONENTS FORM - SELECT ADAPTER TYPE */}
						<FormSelectAdapterType
							inputValue={adapter.type}
							inputOnChange={onChangeFormValue}
							inputLabelName='Adapter Type'
							inputVariableName='type'
						/>
						{/** COMPONENTS FORM - INPUT VERSION */}
						<FormInputText
							inputValue={adapter.version}
							inputLabelName={t("adapter.version")}
							inputOnChange={onChangeFormValue}
							inputVariableName='version'
							isRequired
							disabledInput={false}
						/>
						{/** COMPONENTS FORM - INPUT SOCKET ADDRESS */}
						<FormInputIPandPort
							inputValue={adapter.socket_address}
							inputLabelName={t("edgeManagement.channels.socketAddress")}
							inputOnChange={onChangeFormValue}
							inputVariableName='socket_address'
						/>
						{/** COMPONENTS FORM - SELECT AUTH TYPE */}
						<FormSelectProtocolType
							inputValue={adapter.protocol_type}
							inputOnChange={onChangeFormValue}
							inputLabelName={t("general.protocol")}
							inputVariableName='protocol_type'
						/>
						{/** COMPONENTS FORM - INPUT TEXT AREA DICTIONARY */}
						<FormInputTextArea
							inputValue={jsonStringDictionary}
							inputLabelName={t("adapter.dictionary")}
							inputOnChange={onChangeJsonString}
							inputVariableName='dictionary'
							isRequired
						/>
						{/** COMPONENTS FORM - INPUT TEXT AREA BACNET CONFIG */}
						<FormInputTextArea
							inputValue={jsonStringBacnetConfig}
							inputLabelName={t("adapter.bacnetConfig")}
							inputOnChange={onChangeJsonString}
							inputVariableName='bacnet_config'
							isRequired={false}
						/>
					</div>
				</div>
				<br />
				{/** ************************************************* */}
				{/** COMPONENT DRAWER DIVIDER */}
				<DrawerRowDivider icon={faPlugCircleCheck} title={t("adapter.authTypeInformation")} />
				{/** ------------------------------------------------------------- */}
				{/** AUTH TYPE INFORMATION  */}
				<div className='container'>
					<div className={`drawer__box__${theme} row`}>
						{/** COMPONENTS FORM - SELECT AUTH TYPE */}
						<FormSelectAuthType
							inputValue={adapter.auth_type}
							inputOnChange={onChangeFormValue}
							inputLabelName='Auth Type'
							inputVariableName='auth_type'
						/>
						{/** COMPONENTS FORM - INPUT AUTH TYPE USERNAME */}
						{(formCreateEdit === "CREATE" || formCreateEdit === "DUPLICATE") && (
							<FormInputText
								inputValue={adapter.auth_credentials.username}
								inputLabelName={t("general.userName")}
								inputOnChange={onChangeFormValueAuthCredentials}
								inputVariableName='username'
								isRequired
								disabledInput={false}
							/>
						)}
						{/** COMPONENTS FORM - INPUT AUTH TYPE PASSWORD */}
						{(formCreateEdit === "CREATE" || formCreateEdit === "DUPLICATE") && (
							<FormInputText
								inputValue={adapter.auth_credentials.password}
								inputLabelName={t("general.password")}
								inputOnChange={onChangeFormValueAuthCredentials}
								inputVariableName='password'
								isRequired
								disabledInput={false}
							/>
						)}
					</div>
				</div>
				{/** ---------------------------------------------------- */}
				{/** COMPONENT CLOSE SAVE BUTTON */}
				<DrawerCloseSaveButton
					onClickClose={onClickClose}
					onClickSave={onClickSave}
					disableSaveButton={!checkFormIsValid()}
				/>
				{/** ---------------------------------------------------- */}
				{/** GLOBAL ALERT */}
				<GlobalAlert2
					isError={isErrorPostAdapter}
					isSuccess={isSuccessPostAdapter}
					requestType='POST'
					error={errorPostAdapter}
					name='Adapter'
				/>
				<GlobalAlert2
					isError={isErrorPutAdapter}
					isSuccess={isSuccessPutAdapter}
					requestType='PUT'
					error={errorPutAdapter}
					name='Adapter'
				/>
			</Spin>
		</Drawer>
	);
}

export default AdaptersCreateEditForm;
